import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Carousel from '../components/Carousel';
import MenuSection from '../components/MenuSection.js';
import homeStyle from '../styles/home.scss';

const HomePage = ({ data, style }) => {
  const {
    allWpPage: {
      edges: [
        {
          node: {
            title,
            content,
            homeField: {
              heroCarousel,
              membersCollaborators,
              news,
              haveYourSay,
              frequentlyAskedQuestions,
            } = {},
          } = {},
        } = {},
      ] = [],
    } = {},
  } = data;


  return (
  <>
    <Layout style={style}>
      <Seo title={title} />
      {heroCarousel && <Carousel data={heroCarousel} />}
      {
        membersCollaborators && news && haveYourSay && frequentlyAskedQuestions && (
          <MenuSection data={[membersCollaborators, news, haveYourSay, frequentlyAskedQuestions]} />
        )
      }
      <div className='home-container'>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Layout>
  </>
  )
};

export const pageQuery = graphql`
query{

  allWpPage(filter: {id: {eq: "cG9zdDozMTU="}}) {
    edges {
      node {
        id
        uri
        title
        slug
        content
        homeField {
          fieldGroupName
          haveYourSay {
            color
            destination
            fieldGroupName
            text
            icon {
              sourceUrl
              altText
            }
          }
          membersCollaborators {
            color
            destination
            fieldGroupName
            text
            icon {
              sourceUrl
              altText
            }
          }
          frequentlyAskedQuestions {
            color
            destination
            fieldGroupName
            text
            icon {
              sourceUrl
              altText
            }
          }
          news {
            color
            destination
            fieldGroupName
            text
            icon {
              sourceUrl
              altText
            }
          }
          heroCarousel {
            buttonCopy
            buttonDestination
            fieldGroupName
            image {
              altText
              sourceUrl
            }
            mobileImage {
              altText
              sourceUrl
            }
            text
            title
          }
        }
      }
    }
  }
}
`;


const HomePageStyled = (props) => (
  <HomePage {...props} style={homeStyle} />
);

HomePage.propTypes = {
  data: PropTypes.shape({}),
  style: PropTypes.string
};

HomePage.defaultProps = {
  data: {},
  style: ''
};

HomePageStyled.propTypes = {
  data: PropTypes.shape({})
};

HomePageStyled.defaultProps = {
  data: {}
};

export default HomePageStyled;
