import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint, withBreakpoints } from 'gatsby-plugin-breakpoints';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { css } from '@emotion/react';
import HeroImage from './HeroImage';
import HeroImageText from './HeroImageText';
import carouselHeroImageStyle from '../styles/carouselHeroImage.scss';
import carouselStyle from '../styles/carousel.scss';

const CarouselItem = ({ data, style }) => {
    const breakpoints = useBreakpoint();
    const [isMinWidthMobile, setisMinWidthMobile] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const carouselChange = (index) => {
        setCarouselIndex(index)
    };

    useEffect(() => {
        Array.from(document.querySelectorAll('.dot')).forEach((node) => {
            node.removeAttribute('role');
        });
    }, [])

    useEffect(() => {
        if (breakpoints.sm !== isMinWidthMobile) {
            setisMinWidthMobile(breakpoints.sm);
        }
    }, [isMinWidthMobile, breakpoints]);
    return (
        <>
            <Carousel key='carousel' infiniteLoop={true} autoPlay={true} interval={4000} onChange={carouselChange} showArrows={false} showStatus={false} showThumbs={false} swipeable={true} emulateTouch={true} css={[css(carouselStyle), css(style)]} >
                {
                    data.map((carousel, index) => (
                        <HeroImage image={!isMinWidthMobile ? carousel.image?.sourceUrl : carousel.mobileImage?.sourceUrl} style={carouselHeroImageStyle} key={index} >
                            {
                                !isMinWidthMobile && (
                                    <div className='carousel-description' key={index}>
                                        <div className='carousel-description-text'>
                                            {index === 0 ? <h1>{carousel.title}</h1> : <h2>{carousel.title}</h2>}
                                            <p>{carousel.text}</p>
                                            <a href={carousel.buttonDestination}>{carousel.buttonCopy}</a>
                                        </div>
                                    </div>
                                )
                            }
                        </HeroImage>
                    ))
                }
            </Carousel>
            {
                isMinWidthMobile && (
                    <HeroImageText>
                        <div className='carousel-description'>
                            <div className='carousel-description-text'>
                                {carouselIndex === 0 ? <h1>{data[carouselIndex].title}</h1> : <h2>{data[carouselIndex].title}</h2>}
                                <p>{data[carouselIndex].text}</p>
                                <a href={data[carouselIndex].buttonDestination}>{data[carouselIndex].buttonCopy}</a>
                            </div>
                        </div>
                    </HeroImageText>
                )
            }
        </>
    )
}

const CarouselItemStyled = (props) => {
    return <CarouselItem {...props} style={props.style} />
}

CarouselItem.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

CarouselItem.defaultProps = {
    data: [],
    style: ''
};

CarouselItemStyled.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

CarouselItemStyled.defaultProps = {
    data: [],
    style: ''
};

export default withBreakpoints(CarouselItemStyled);
