import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react'
import SectionArrow from '../assets/SectionArrow.svg';
import menuSectionStyle from '../styles/menuSection.scss';

const MenuSection = ({ data, style }) => {
    return (
        <div
            className={`menu-select-section`}
            css={[css(menuSectionStyle), css(style)]}
        >
            <ul>
                {
                    data.map((section, index) => (
                        <li css={css`
                            border-bottom: 4px solid${section.color}
                        `}
                            key={index}
                        >
                            <a href={section.destination}>
                                {
                                    section.icon && <img src={section.icon.sourceUrl} alt={section.icon.altText} />
                                }
                                <div>
                                    <h3>{section.text}</h3>
                                    <SectionArrow />
                                </div>
                            </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

const MenuSectionStyled = (props) => (
    <MenuSection {...props} style={props.style} />
);

MenuSection.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

MenuSection.defaultProps = {
    data: [],
    style: ''
};

MenuSectionStyled.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

MenuSectionStyled.defaultProps = {
    data: [],
    style: ''
};

export default MenuSectionStyled;